import React, { useEffect, useState } from 'react';
import EventCard from '../components/EventCard';
import LandingPage from '../components/LandingPage';
import styled from 'styled-components';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { getEvents } from '../api/eventService';
import theme from '../styles/Theme';
import { useNavigate } from "react-router-dom";


const Container = styled.div`
  padding: 20px;
  display:flex;
  flex-direction:row;
`;

const HomePage = () => {
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchEvents() {
      const eventList = await getEvents();
      setEvents(eventList);
    }
    fetchEvents();
  }, []);


  const handleEventClick = (idEvent) => {
    console.log('teste')
    console.log('teste')
    navigate(`/checkout/${events}/${idEvent}`);
  };

  return (
    <>
      <Navbar props = {theme}  />
      <LandingPage />
      <Container>
        {events.map((event) => (
            <div onClick={() => handleEventClick(event._id)}>

                <EventCard key={event.id} 
                event={event} 
                onClick={() => handleEventClick(event._id)}
                />
            </div>
        ))}
      </Container>
      <Footer />
    </>
  );
};

export default HomePage;
