import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getEvents } from "../../api/eventService";
import "./CheckoutVenda.css"; // Estilos profissionais
import { getUserSession } from "../../utils/storage";
import { apartamentos } from "../../utils/apartamentos";
import { isValidCPF } from "../../utils/cpfValidator";
import api from "../../api/api";

import {
  Stepper,
  Step,
  StepLabel,
  TextField,
  Select,
  MenuItem,
  Button as MUIButton,
  Snackbar,
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  CircularProgress,
} from "@mui/material";
import QRCodeInfo from "../../components/QRCodeInfo";
import EventCard from "./EventCard";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import PixIcon from "@mui/icons-material/QrCode2";
import MoneyIcon from "@mui/icons-material/AttachMoney";

const CheckoutVenda = () => {
  const { idEvent } = useParams();
  const [ingressos, setIngressos] = useState([]);
  const [formaPagamento, setFormaPagamento] = useState("Pix");
  const [valorTotal, setValorTotal] = useState(0);
  const [valorDesc, setValorDes] = useState(0);
  const [ingressosComDesconto, setIngressosComDesconto] = useState(0);
  const [txCard, setTxCard] = useState(0);
  const [userData, setUserData] = useState({
    nome: "",
    email: "",
    cpf: "",
    apartamento: "",
    torre: "",
    telefone: "",
    password: "",
  });
  const [events, setEvents] = useState([]);

  const [qrCodeData, setQrCodeData] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [urlMp, setUrlMp] = useState("");
  const [reponseData, setReponseData] = useState("");
  const [loadingAction, setloandingAction] = useState(false); // Define o número de vendas por página
  const [modalOpen, setModalOpen] = useState(false);

  const navigate = useNavigate();

  const hadleCloseModal = async () => {
    setModalOpen(false);
    setPaymentModalOpen(false);
    setQrCodeData(null);
    setUrlMp("");
    navigate("/login"); // Redireciona para a página de login
  };

  useEffect(() => {
    async function fetchEvents() {
      const eventList = await getEvents();
      setEvents(eventList);
      const { user } = getUserSession();
      setUserData(user);
      handleUserDataChange("nome", user.name);
    }
    fetchEvents();

    // Simulação de dados do usuário logado
  }, []);

  const evento = events.find((e) => e._id === idEvent);
  const valorIngresso = evento ? evento.valor : 0;

  const handleAddIngresso = async () => {
    // Cria uma cópia atualizada do array ingressos
    const ingressosAtualizados = [
      ...ingressos,
      { userId: "", nomeConvidado: "", camisa: "G" }, // Ingresso padrão
    ];

    // Atualiza o estado de ingressos
    setIngressos(ingressosAtualizados);

    await calcularValorIngresso(idEvent, ingressosAtualizados, formaPagamento);
  };

  const handleChangeIngresso = (index, field, value) => {
    const updatedIngressos = [...ingressos];
    updatedIngressos[index][field] = value;
    setIngressos(updatedIngressos);
    calcularValorIngresso(idEvent, updatedIngressos, formaPagamento);
  };

  const handleRemoveIngresso = (index) => {
    const updatedIngressos = ingressos.filter((_, i) => i !== index);
    setIngressos(updatedIngressos);
    calcularValorIngresso(idEvent, updatedIngressos, formaPagamento);
  };

  const handleUserDataChange = (field, value) => {
    setUserData({ ...userData, [field]: value });
  };

  function confirmarAcao(message) {
    // eslint-disable-next-line no-restricted-globals
    return confirm(message);
  }

  const calcularValorIngresso = async (
    idEvent,
    ingressosAtualizados,
    formaPagamento
  ) => {
    try {
      const data = {
        idEvent,
        ingressos: ingressosAtualizados,
        formaPagamento,
      };
      const response = await api.post("api/vendas/calcIngresso", data);
      console.log(response);

      setValorTotal(response.data.valorTotal);
      setValorDes(response.data.valorDesconto);
      setTxCard(response.data.txCard);
      setIngressosComDesconto(response.data.ingressosComDesconto);
    } catch (error) {
      console.log(error);
      if (error.response) {
        setErrorMessage("Erro calcular ingresso");
      } else {
        setErrorMessage("ocorreu um erro ao calcular ingresso");
      }
    }
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      const data = {
        ...userData,
        ingressos,
        idEvent,
        formaPagamento,
        valorCompra: valorTotal,
        vendauser: true,
      };

      setloandingAction(true);

      // Enviar os dados ao backend
      console.log(data);

      try {
        if (confirmarAcao("Dados e forma de pagamento estão corretos?")) {
          const response = await api.post("api/vendas/realizar-venda", data);

          console.log(response);
          setloandingAction(false);

          if (formaPagamento === "Pix") {
            setQrCodeData(response.data);
            setSuccessMessage("Venda realizada com sucesso!");
            setModalOpen(true);
          } else if (formaPagamento === "Cartão") {
            setReponseData(response.data);
            if (response.data) {
              console.log(response.data);
              console.log(response.data.venda.preference);

              setUrlMp(response.data.venda.preference.init_point);
              setPaymentModalOpen(true);
            } else {
              setErrorMessage("Erro ao tertar gerar pagamento com cartão");
            }
          } else {
            setSuccessMessage("Venda realizada com sucesso!");
            setModalOpen(true);
          }
        } else {
          setloandingAction(false);
        }
      } catch (error) {
        console.log(error);
        if (error.response) {
          setErrorMessage(
            "Erro ao realizar venda: " + error.response.data.message
          );
        } else {
          setErrorMessage(
            "ocorreu um erro verifique a lista de vendas o status da venda."
          );
        }

        setloandingAction(false);
      }
    }
  };

  const validateForm = () => {
    let errors = [];

    // Validações para userData
    if (!userData?.nome?.trim() || !userData.nome.includes(" ")) {
      errors.push("O nome completo é obrigatório.");
    }

    if (!userData?.email?.trim() || !userData.email.includes("@")) {
      errors.push("O e-mail deve ser válido.");
    }

    if (!userData?.cpf?.trim() || !isValidCPF(userData.cpf)) {
      errors.push("O CPF deve ser válido e no formato correto.");
    }

    if (!userData?.apartamento?.trim()) {
      errors.push("O campo apartamento é obrigatório.");
    }

    // Validação de ingressos
    if (!ingressos || ingressos.length === 0) {
      errors.push("Adicione pelo menos um ingresso.");
    } else {
      ingressos.forEach((ingresso, index) => {
        if (!ingresso?.nomeConvidado?.trim()) {
          errors.push(
            `O nome do convidado é obrigatório para o ingresso ${index + 1}.`
          );
        }

        if (!ingresso?.camisa?.trim()) {
          errors.push(`Selecione uma camisa para o ingresso ${index + 1}.`);
        }
      });
    }

    // Exibir erros, se houver
    if (errors.length > 0) {
      setErrorMessage(errors.join("\n"));
      return false;
    }

    return true;
  };

  const handleFormaPagamento = async (formaPagamentoParam) => {
    setFormaPagamento(formaPagamentoParam);

    await calcularValorIngresso(idEvent, ingressos, formaPagamentoParam);
  };

  const PaymentSuccessModal = ({ open, onClose, imageSrc, ticketUrl }) => {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Pagamento cartão Gerado!</DialogTitle>
        <DialogContent>
          <img
            src={imageSrc}
            alt="logo mercado pago"
            style={{ width: "100%" }}
          />
          <MUIButton
            variant="contained"
            onClick={() => window.open(ticketUrl, "_blank")}
          >
            Finalizar Pagamento no checkout do mercado pago
          </MUIButton>
          <div>* Verifique seu email e entre em sua conta para mais detalhes</div>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <div className="checkout-container">
      <h1 className="checkout-title">
        Compra de Ingresso(s) <br></br> {evento?.nome}{" "}
      </h1>
      <div className="event-info">
        <p>
          <strong>Evento:</strong> {evento?.nome}
        </p>
        <p>
          <strong>Valor Unitário:</strong> R$ {valorIngresso}
        </p>
      </div>

      <div className="user-data">
        <h2>Dados do Comprador</h2>
        <div className="user-data-form">
          <TextField
            placeholder="Nome"
            value={userData !== null ? userData.name : ""}
            type="text"
            onChange={(e) => handleUserDataChange("nome", e.target.value)}
            fullWidth
            required
          />
          <TextField
            placeholder="Email"
            fullWidth
            value={userData != null ? userData.email : ""}
            onChange={(e) => handleUserDataChange("email", e.target.value)}
            required
            margin="normal"
          />
          <TextField
            placeholder="CPF"
            value={userData != null ? userData.cpf : ""}
            type="number"
            onChange={(e) => handleUserDataChange("cpf", e.target.value)}
            fullWidth
            required
          />

          <TextField
            placeholder="Telefone"
            value={userData != null ? userData.telefone : ""}
            type="number"
            onChange={(e) => handleUserDataChange("telefone", e.target.value)}
            required
          />
          <TextField
            placeholder="Senha"
            fullWidth
            type="password"
            // value={""}
            onChange={(e) => handleUserDataChange("password", e.target.value)}
            required
            margin="normal"
          />
          <label>Torre</label>
          <Select
            placeholder="Torre"
            fullWidth
            value={userData != null ? userData.torre : ""}
            onChange={(e) => handleUserDataChange("torre", e.target.value)}
            required
            margin="normal"
          >
            <MenuItem value="Acqua">Acqua</MenuItem>
            <MenuItem value="Natura">Natura</MenuItem>
          </Select>
          <label>Apartamento</label>

          <Select
            placeholder="Apartamento"
            fullWidth
            value={userData != null ? userData.apartamento : ""}
            onChange={(e) =>
              handleUserDataChange("apartamento", e.target.value)
            }
            required
            margin="normal"
          >
            {apartamentos.map((apart) => (
              <MenuItem key={apart} value={apart}>
                {apart}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>

      <div className="ingressos-container">
        <h2>Ingressos</h2>
        {ingressos.map((ingresso, index) => (
          <div key={index} className="ingresso-card">
            {/* <label>
              User ID:
              <input
                type="text"
                value={ingresso.userId}
                onChange={(e) =>
                  handleChangeIngresso(index, "userId", e.target.value)
                }
              />
            </label> */}
            <label>
              Nome:
              <input
                type="text"
                value={ingresso.nomeConvidado}
                onChange={(e) =>
                  handleChangeIngresso(index, "nomeConvidado", e.target.value)
                }
              />
            </label>
            <label>
              Camisa:
              <select
                value={ingresso.camisa}
                onChange={(e) =>
                  handleChangeIngresso(index, "camisa", e.target.value)
                }
              >
                <option value="2">2 - Infantil</option>
                <option value="4">4 - Infantil</option>
                <option value="6">6 - Infantil</option>
                <option value="8">8 - Infantil</option>
                <option value="12">12 - Infantil</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="G">G</option>
                <option value="GG">GG</option>
                <option value="XG">XG</option>
              </select>
            </label>
            <button
              className="remove-button"
              onClick={() => handleRemoveIngresso(index)}
            >
              Remover
            </button>
          </div>
        ))}
        <div>
          <label>
            * Ingresso infantil exclusivo para crianças com idade até 13 anos. R$ 30,00 -Direito apenas a camisa-
          </label>
        </div>
        <div>
          <label>
            * Compra de ingressos exclusivo para moradores, os moradores devem
            efetuar a compra para seu convidado.
          </label>
        </div>
        <button className="add-button" onClick={handleAddIngresso}>
          Adicionar Ingresso
        </button>
      </div>
      {ingressos.length > 0 && (
        <>
          <div className="payment-container">
            {/* Seleção de Formas de Pagamento com Ícones */}
            <FormControl component="fieldset">
              <FormLabel component="legend">Forma de Pagamento</FormLabel>
              <RadioGroup
                value={formaPagamento}
                onChange={(e) => handleFormaPagamento(e.target.value)}
              >
                <FormControlLabel
                  value="Pix"
                  control={<Radio />}
                  label={
                    <>
                      <PixIcon /> Pix
                    </>
                  }
                />
                <FormControlLabel
                  value="Cartão"
                  control={<Radio />}
                  label={
                    <>
                      <CreditCardIcon /> Cartão
                    </>
                  }
                />
              </RadioGroup>
            </FormControl>
          </div>
          <h4>Valor Ingressos: R$ {valorIngresso * ingressos.length}</h4>
          <h4>Quantidade Ingressos: {ingressos.length}</h4>
          <h4>Ingressos com desconto: {ingressosComDesconto}</h4>
          <h4>Valor desconto: R$ {valorDesc}</h4>
          {formaPagamento === "Cartão" && (
            <h4>Taxa Cartão: R$ {txCard.toFixed(2)}</h4>
          )}
          <h2 className="total-value">Valor Total: R$ {valorTotal}</h2>
        </>
      )}
      {loadingAction ? (
        <CircularProgress></CircularProgress>
      ) : (
        <button className="submit-button" onClick={handleSubmit}>
          Finalizar Compra
        </button>
      )}

      {/* Modal de QR Code após pagamento */}
      <Dialog open={modalOpen} onClose={() => hadleCloseModal()}>
        <DialogTitle>Pagamento Gerado com Sucesso!</DialogTitle>
        <DialogContent>
          {qrCodeData && qrCodeData.pagamento && (
            <QRCodeInfo
              qr_code={qrCodeData.pagamento.codigoPix}
              ticket_url={qrCodeData.pagamento.urlPix}
              qr_code_base64={qrCodeData.pagamento.imageqrCode}
              description={qrCodeData.venda.pagamento.description}
              buyerName={userData.nome}
              apartment={userData.apartamento}
              tower={userData.torre}
            />
          )}
          <div>* Verifique seu email e logue em sua conta para mais detalhes</div>
        </DialogContent>
      </Dialog>

      {/* Modal para Pagamento com Cartão */}
      <PaymentSuccessModal
        open={paymentModalOpen}
        onClose={() => hadleCloseModal()}
        imageSrc={"/images/mercadopago.png"}
        ticketUrl={urlMp}
      />

      {/* Mensagens de Sucesso e Erro */}
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage("")}
      >
        <Alert onClose={() => setSuccessMessage("")} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
      {errorMessage && (
        <Snackbar
          open
          autoHideDuration={10000}
          onClose={() => setErrorMessage("")}
        >
          <Alert severity="error" onClose={() => setErrorMessage("")}>
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
};

export default CheckoutVenda;
