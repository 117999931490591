import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import api from "../api/api";
import { useNavigate } from "react-router-dom";

// Estilização
const PageContainer = styled.div`
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: bold;
  color: #800020;
  text-align: center;
  margin-bottom: 40px;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  background: linear-gradient(90deg, rgba(128, 0, 32, 1) 0%, rgba(255, 255, 255, 1) 100%);
  -webkit-background-clip: text;
  color: transparent;
`;

const QrCodeImage = styled.img`
  width: 200px;
  height: 200px;
  margin-top: 10px;
`;

const MinhasCompras = () => {
  const [compras, setCompras] = useState([]);
  const [selectedCompra, setSelectedCompra] = useState(null); // Compra selecionada para o modal
  const [open, setOpen] = useState(false); // Estado para abrir/fechar modal de detalhes
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchCompras();
  }, []);

  // Função para buscar compras do usuário logado
  const fetchCompras = async () => {
    setLoading(true);
    try {
      const response = await api.get("/api/vendas/minhas-compras"); // Altere para a rota correta da API de compras
      console.log(response)
      setCompras(response.data.vendas);
      setLoading(false);
    } catch (error) {
      console.error("Erro ao buscar as compras", error);
      setLoading(false);
    }
  };

  // Função para abrir o modal e carregar os dados da compra
  const handleOpenModal = (compra) => {
    setSelectedCompra(compra);
    setOpen(true);
  };

  // Função para fechar o modal
  const handleCloseModal = () => {
    setOpen(false);
    setSelectedCompra(null);
  };

  return (
    <PageContainer>
      <Title>Minhas Compras</Title>

      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Evento</TableCell>
                <TableCell>Mesas / Ingressos</TableCell>
                <TableCell>Tamanho camisa(s)</TableCell>
                <TableCell>Valor</TableCell>
                <TableCell>Forma de Pagamento</TableCell>
                <TableCell>Status do Pagamento</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {compras.map((compra) => (
                <TableRow key={compra._id}>
                  <TableCell>{compra.idEvent.nome}</TableCell>
                  {compra.mesasRelacionadas !== undefined &&
                  compra.mesasRelacionadas.length!==0 ? (
                    <TableCell>
                      {compra.mesasRelacionadas
                        .map((mesa) => mesa.numero)
                        .join(", ")}
                    </TableCell>
                  ) : (
                    <TableCell>{compra.ingressos.length}</TableCell>
                  )}

                  <TableCell>{compra.camisasIngressos}</TableCell>
                  <TableCell>R$ {compra.valorCompra.toFixed(2)}</TableCell>
                  <TableCell>R$ {compra.valorCompra.toFixed(2)}</TableCell>
                  <TableCell>{compra.formaPagamento}</TableCell>
                  <TableCell
                    style={{
                      backgroundColor: compra.statusPagamento === "Pago" ? "#ccffcc" : "transparent",
                    }}
                  >
                    {compra.statusPagamento}
                  </TableCell>
                  <TableCell>
                    <Button variant="outlined" onClick={() => handleOpenModal(compra)}>
                      Detalhes
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Modal para detalhes da compra */}
      {selectedCompra && (
        <Dialog open={open} onClose={handleCloseModal}>
          <DialogTitle>Detalhes da Compra</DialogTitle>
          <DialogContent>
            <p><strong>Evento:</strong> {selectedCompra.idEvent.nome}</p>
            {/* <p><strong>Mesas:</strong> {selectedCompra.mesasRelacionadas.map((mesa) => mesa.numero).join(", ")}</p> */}
            <p><strong>Valor Total:</strong> R$ {selectedCompra.valorCompra.toFixed(2)}</p>

            {/* Exibição de QR Code e informações de pagamento */}
            {selectedCompra.formaPagamento === "Pix" && selectedCompra.pagamento && (
              <QRCodeInfo
                qr_code={selectedCompra.pagamento.point_of_interaction.transaction_data.qr_code}
                ticket_url={selectedCompra.pagamento.point_of_interaction.transaction_data.ticket_url}
                qr_code_base64={selectedCompra.pagamento.point_of_interaction.transaction_data.qr_code_base64}
                description={selectedCompra.pagamento.description}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal}>Fechar</Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Mensagens de Sucesso e Erro */}
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage("")}
      >
        <Alert onClose={() => setSuccessMessage("")} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage("")}
      >
        <Alert onClose={() => setErrorMessage("")} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

// Componente para exibir o QR Code e as informações de pagamento
const QRCodeInfo = ({ qr_code, ticket_url, qr_code_base64, description }) => {
  return (
    <div>
      <p><strong>QR Code:</strong></p>
      <QrCodeImage src={`data:image/png;base64,${qr_code_base64}`} alt="QR Code" />
      <p><a href={ticket_url} target="_blank" rel="noopener noreferrer">Clique aqui para visualizar o ticket</a></p>
      <p><strong>Descrição:</strong> {description}</p>
    </div>
  );
};

export default MinhasCompras;
