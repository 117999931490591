import React from 'react';
import styled from 'styled-components';

const Card = styled.div`
  background-color: ${(props) => props.theme.colors.cardBackground};
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin: 16px;
  width:300px;
  text-align: center;
   &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    background-color: 	#FAF0E6;
  cursor: pointer;

  }
`;

const Image = styled.img`
  width: 100%;
  height: 200px;
  object-fit: contain;
  border-radius: 8px;
//   background-color: #f0f0f0; /* Adiciona um fundo para quando a imagem não preencher o espaço */
`;


const EventName = styled.h3`
  color: ${(props) => props.theme.colors.primary};
  margin-top: 8px;
`;

const EventDescription = styled.p`
  color: ${(props) => props.theme.colors.text};
  font-size: 14px;
  margin: 8px 0;
`;

const EventPrice = styled.p`
  color: ${(props) => props.theme.colors.secondary};
  font-weight: bold;
`;

const EventCard = ({ event }) => (
    <>
    {event.status==="Ativo" && 
        <Card>
          <Image src={event.foto} alt={event.nome} />
          <EventName>{event.nome}</EventName>
          <EventName>Compre aqui seu ingresso!</EventName>
          <EventDescription>{event.descricao}</EventDescription>
          {/* <EventPrice>{`R$ ${event.valor}`}</EventPrice> */}
        </Card>

    }
    </>
);

export default EventCard;
