import React, { useState, useEffect } from "react";
import styled from "styled-components";
import TableRestaurantRoundedIcon from "@mui/icons-material/TableRestaurantRounded";
import TableBarRoundedIcon from "@mui/icons-material/TableBarRounded";
import LoopRoundedIcon from "@mui/icons-material/LoopRounded";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  TextField,
  Snackbar,
  Alert,
  IconButton,
  CircularProgress,
} from "@mui/material";
import api from "../../api/api";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import { Link, redirect, useNavigate } from "react-router-dom";

// Estilização
const PageContainer = styled.div`
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: bold;
  color: #800020; /* Cor vinho para harmonia */
  text-align: center;
  margin-bottom: 40px;
  text-transform: uppercase; /* Transformar o texto para maiúsculas */
  letter-spacing: 2px; /* Espaçamento entre letras */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Sombras sutis para destacar o texto */
  background: linear-gradient(
    90deg,
    rgba(128, 0, 32, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  -webkit-background-clip: text;
  color: transparent; /* Efeito gradiente */
`;

const QrCodeImage = styled.img`
  width: 200px;
  height: 200px;
  margin-top: 10px;
`;

// Função para listar vendas
const ListaVenda = () => {
  const [vendas, setVendas] = useState([]);
  const [selectedVenda, setSelectedVenda] = useState(null); // Venda selecionada para o modal
  const [statusPagamento, setStatusPagamento] = useState("");
  const [formaPagamento, setFormaPagamento] = useState("");
  const [open, setOpen] = useState(false); // Estado para abrir/fechar modal de detalhes
  const [openTroca, setOpenTroca] = useState(false); // Estado para abrir/fechar modal de troca de mesas
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [mesas, setMesas] = useState([]); // Mesas disponíveis no evento
  const [mesasSelecionadas, setMesasSelecionadas] = useState([]); // Mesas selecionadas para troca
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10); // Define o número de vendas por página
  const [loading, setloanding] = useState(false); // Define o número de vendas por página
  const [loadingAction, setloandingAction] = useState(false); // Define o número de vendas por página
  const [nomeFiltro, setNomeFiltro] = useState("");

  const [dataInicio, setDataInicio] = useState("");
  const [dataFim, setDataFim] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchVendas();
  }, [page, nomeFiltro, statusPagamento, formaPagamento, dataInicio, dataFim,limit]);

  // Função para buscar vendas
  const fetchVendas = async () => {
    try {
      const response = await api.get(`/api/vendas`, {
        params: {
          page,
          limit,
          nomeComprador: nomeFiltro,
          statusPagamento,
          formaPagamento,
          dataInicio,
          dataFim,
        },
      });
      setVendas(response.data.vendas);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Erro ao buscar as vendas", error);
    }
  };

  // Função para abrir o modal e carregar os dados da venda
  const handleOpenModal = (venda) => {
    setSelectedVenda(venda);
    setStatusPagamento(venda.statusPagamento);
    setFormaPagamento(venda.formaPagamento);
    setOpen(true);
  };

  // Função para fechar o modal
  const handleCloseModal = () => {
    setOpen(false);
    setSelectedVenda(null);
  };

  // Função para abrir o modal de troca de mesas
  const handleOpenTroca = (venda) => {
    setSelectedVenda(venda);
    setMesasSelecionadas(venda.mesasRelacionadas.map((m) => m.numero)); // Mesas relacionadas já compradas
    fetchMesas(venda.idEvent._id); // Busca as mesas disponíveis no evento
    // setMesas(venda.idEvent.mesas)
    setOpenTroca(true);
  };

  // Função para buscar mesas disponíveis no evento
  const fetchMesas = async (eventId) => {
    try {
      const response = await api.get(`/api/events/event/${eventId}`);
      setMesas(response.data.mesas); // Popula as mesas do evento
    } catch (error) {
      console.error("Erro ao buscar as mesas", error);
    }
  };

  // Função para fechar o modal de troca de mesas
  const handleCloseTroca = () => {
    setOpenTroca(false);
    setMesasSelecionadas([]);
  };

  // Função para salvar as alterações de status e forma de pagamento
  const handleSave = async () => {
    try {
      const updatedData = {
        statusPagamento,
        formaPagamento,
      };
      setloandingAction(true);
      await api.put(`/api/vendas/${selectedVenda._id}`, updatedData);
      setSuccessMessage("Venda atualizada com sucesso!");
      setOpen(false);
      setloandingAction(false);
      fetchVendas(); // Atualiza a lista de vendas
    } catch (error) {
      setloandingAction(false);

      setErrorMessage("Erro ao atualizar a venda!");
    }
  };

  // Função para salvar a troca de mesas
  const handleSaveTroca = async () => {
    try {
      //   await api.put(`/api/vendas/${selectedVenda._id}/trocar-mesas`, { mesas: mesasSelecionadas });
      setloandingAction(true);

      await trocarMesas(selectedVenda._id, mesasSelecionadas);
      setSuccessMessage("Mesas trocadas com sucesso!");
      setOpenTroca(false);
      setloandingAction(false);

      fetchVendas(); // Atualiza a lista de vendas
    } catch (error) {
      setloandingAction(false);
      setErrorMessage("Erro ao trocar as mesas!");
    }
  };
  function confirmarAcao(message) {
    // eslint-disable-next-line no-restricted-globals
    return confirm(message);
  }

  const handleLiberarMesa = async (id) => {
    if (
      confirmarAcao(
        "Deseja liberar as mesas? Não será possivel reverter a ação."
      )
    ) {
      try {
        setloandingAction(true);

        await api.post(`/api/events/liberarMesa`, { id: id });

        setSuccessMessage("Mesas Liberadas!");
        setOpenTroca(false);
        setloandingAction(false);

        fetchVendas(); // Atualiza a lista de vendas
      } catch (error) {
        setloandingAction(false);
        setErrorMessage("Ocorreu um erro: " + error.response.data.message);
      }
    }
  };

  const trocarMesas = async (vendaId, novasMesas) => {
    try {
      await api.put(`/api/vendas/${vendaId}/trocar-mesas`, { novasMesas });
      console.log("Mesas trocadas com sucesso!");
    } catch (error) {
      console.error("Erro ao trocar as mesas:", error);
    }
  };

  const hadleSicronize = async (vendaId) => {
    const data = {
      id: vendaId,
    };
    try {
      setloandingAction(true);

      await api.post(`/api/sycronize`, data);
      setloandingAction(false);

      setSuccessMessage("Pagamento atualizado!");

      fetchVendas(); // Atualiza a lista de vendas
    } catch (error) {
      setloandingAction(false);

      console.error("Erro ao sicronizar:", error);
      setErrorMessage("Erro na sicronização: !" + error.response.data.message);
    }
  };

  const hadleCheckOut = async (url) => {
    window.open(url, "_blank");
  };

  // Função para selecionar mesas para troca
  const handleSelectMesa = (numeroMesa) => {
    if (mesasSelecionadas.includes(numeroMesa)) {
      setMesasSelecionadas(mesasSelecionadas.filter((m) => m !== numeroMesa));
    } else if (
      mesasSelecionadas.length < selectedVenda.mesasRelacionadas.length
    ) {
      setMesasSelecionadas([...mesasSelecionadas, numeroMesa]);
    }
  };

  function copyToClipboard(text) {
    // Cria um elemento de input temporário para armazenar o texto a ser copiado
    const tempInput = document.createElement("input");

    // Define o valor do input como o texto que será copiado
    tempInput.value = text;

    // Adiciona o input temporário ao body
    document.body.appendChild(tempInput);

    // Seleciona o conteúdo do input
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // Para dispositivos móveis

    // Executa o comando de cópia
    document.execCommand("copy");

    // Remove o input temporário
    document.body.removeChild(tempInput);

    // Exibe uma mensagem de sucesso, opcional
    alert("URL copiada para a área de transferência!");
  }

  return (
    <PageContainer>
      <Title>Lista de Vendas</Title>

      <div
        style={{
          display: "flex",
          gap: "10px",
          marginBottom: "20px",
          flexWrap: "wrap",
        }}
      >
        <TextField
          label="Nome"
          variant="outlined"
          value={nomeFiltro}
          onChange={(e) => setNomeFiltro(e.target.value)}
        />

        <Select
          value={statusPagamento}
          onChange={(e) => setStatusPagamento(e.target.value)}
          displayEmpty
        >
          <MenuItem value="">Todos Status</MenuItem>
          <MenuItem value="Pendente">Pendente</MenuItem>
          <MenuItem value="Pago">Pago</MenuItem>
          <MenuItem value="Cancelado">Cancelado</MenuItem>
        </Select>

        <Select
          value={formaPagamento}
          onChange={(e) => setFormaPagamento(e.target.value)}
          displayEmpty
        >
          <MenuItem value="">Todas Formas</MenuItem>
          <MenuItem value="Cartão">Cartão</MenuItem>
          <MenuItem value="Boleto">Boleto</MenuItem>
          <MenuItem value="Pix">Pix</MenuItem>
        </Select>

        <TextField
          label="Data Início"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={dataInicio}
          onChange={(e) => setDataInicio(e.target.value)}
        />

        <TextField
          label="Data Fim"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={dataFim}
          onChange={(e) => setDataFim(e.target.value)}
        />
      </div>

      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nome do Comprador</TableCell>
                <TableCell>Telefone / Email</TableCell>
                <TableCell>Apartamento</TableCell>
                <TableCell>Mesas / Camisas</TableCell>
                <TableCell>Evento</TableCell>
                <TableCell>Valor</TableCell>
                <TableCell>Forma de Pagamento</TableCell>
                <TableCell>Status do Pagamento</TableCell>
                <TableCell>Data e Hora</TableCell> {/* Novo campo adicionado */}
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vendas.map((venda) => (
                <TableRow key={venda._id}>
                  <TableCell>{venda.idUserComprador.name}</TableCell>
                  <TableCell>
                    <a
                      href={`https://wa.me/55${venda.idUserComprador.telefone}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <WhatsAppIcon
                          style={{ color: "#25D366", marginLeft: "8px" }}
                        />
                        {venda.idUserComprador.telefone}{" "}
                      </div>
                    </a>
                    {venda.idUserComprador.email}{" "}
                  </TableCell>
                  <TableCell>
                    {venda.idUserComprador.apartamento} -{" "}
                    {venda.idUserComprador.torre}{" "}
                  </TableCell>
                  {venda.mesasRelacionadas !== undefined &&
                  venda.mesasRelacionadas.length !== 0 ? (
                    <TableCell>
                      {venda.mesasRelacionadas
                        .map((mesa) => mesa.numero)
                        .join(", ")}
                    </TableCell>
                  ) : (
                    // <TableCell>{venda.ingressos.length}</TableCell>

                    <TableCell>
                    {venda.ingressos
                      .map((ingresso) => ingresso.camisa)
                      .join(" | ")}
                  </TableCell>
                  )}
                  <TableCell>{venda.idEvent.nome}</TableCell>
                  <TableCell>
                    R${" "}
                    {venda.pagamento !== undefined &&
                    venda.pagamento.transaction_details !== undefined &&
                    (venda.formaPagamento === "Pix" ||
                      venda.formaPagamento === "Cartão")
                      ? venda.pagamento.transaction_details.net_received_amount
                      : venda.valorCompra.toFixed(2)}
                  </TableCell>
                  <TableCell>{venda.formaPagamento}</TableCell>
                  <TableCell
                    style={{
                      backgroundColor:
                        venda.statusPagamento === "Pago"
                          ? "#ccffcc" // Verde claro
                          : venda.statusPagamento === "Pendente"
                          ? "#ffffcc" // Amarelo claro
                          : venda.statusPagamento === "Recusado"
                          ? "#ffcccc" // Vermelho claro
                          : venda.statusPagamento === "Create"
                          ? "#d3d3d3" // Cinza claro
                          : venda.statusPagamento === "Cancelado"
                          ? "#ff9999" // Vermelho
                          : "transparent", // Cor padrão se não encontrar status
                    }}
                  >
                    {venda.statusPagamento}
                  </TableCell>

                  <TableCell
                    style={{
                      color: venda.pagamento === undefined ? "red" : "black",
                    }}
                  >
                    {new Date(venda.dataHora).toLocaleString("pt-BR", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    })}{" "}
                    {/* Formatando e exibindo a data e hora */}
                  </TableCell>

                  <TableCell>
                    <Button
                      variant="outlined"
                      onClick={() => handleOpenModal(venda)}
                    >
                      Detalhes
                    </Button>
                    <IconButton
                      onClick={() => handleOpenTroca(venda)}
                      aria-label="trocar mesas"
                    >
                      <TableRestaurantRoundedIcon />
                    </IconButton>

                    <IconButton
                      onClick={() => hadleSicronize(venda._id)}
                      aria-label="Sicronizar pagamento"
                    >
                      {loadingAction ? (
                        <CircularProgress />
                      ) : (
                        <LoopRoundedIcon />
                      )}
                    </IconButton>

                    {venda.preference && venda.preference.init_point && (
                      <IconButton
                        onClick={() =>
                          copyToClipboard(venda.preference.init_point)
                        }
                        aria-label="Sicronizar pagamento"
                      >
                        <ShoppingCartRoundedIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Select
          value={limit}
          onChange={(e) => setLimit(e.target.value)}
          displayEmpty
        >
          <MenuItem value="10">10</MenuItem>
          <MenuItem value="20">20</MenuItem>
          <MenuItem value="50">50</MenuItem>
          <MenuItem value="100">100</MenuItem>
        </Select>
        <Button disabled={page === 1} onClick={() => setPage(page - 1)}>
          Anterior
        </Button>
        <Select
          value={page}
          onChange={(e) => setPage(Number(e.target.value))}
          style={{ margin: "0 15px" }}
        >
          {[...Array(totalPages).keys()].map((num) => (
            <MenuItem key={num + 1} value={num + 1}>
              {num + 1}
            </MenuItem>
          ))}
        </Select>
        <Button
          disabled={page === totalPages}
          onClick={() => setPage(page + 1)}
        >
          Próxima
        </Button>
      </div>

      {/* Modal para edição de venda */}
      {selectedVenda && (
        <Dialog open={open} onClose={handleCloseModal}>
          <DialogTitle>Detalhes da Venda</DialogTitle>
          <DialogContent>
            <p>
              <strong>Id:</strong> {selectedVenda._id}
            </p>
            <p>
              <strong>Nome do Comprador:</strong>{" "}
              {selectedVenda.idUserComprador.name}
            </p>
            <p>
              <strong>Apartamento:</strong>{" "}
              {selectedVenda.idUserComprador.apartamento}
            </p>
            <p>
              <strong>Evento:</strong> {selectedVenda.idEvent.nome}
            </p>
            {selectedVenda.mesasRelacionadas && (
              <p>
                <strong>Mesas:</strong>{" "}
                {selectedVenda.mesasRelacionadas
                  .map((mesa) => mesa.numero)
                  .join(", ")}
              </p>
            )}

            {selectedVenda.ingressos && (
              <p>
                <strong>Ingressos:</strong>{" "}
                {selectedVenda.ingressos
                  .map((ingresso) => ingresso.camisa)
                  .join(" | ")}
              </p>
            )}
            <p>
              <strong>Valor Total:</strong> R${" "}
              {selectedVenda.valorCompra.toFixed(2)}
            </p>

            {/* Exibição de QR Code e informações de pagamento */}
            {selectedVenda.formaPagamento === "Pix" &&
              selectedVenda.pagamento && (
                <QRCodeInfo
                  qr_code={
                    selectedVenda.pagamento.point_of_interaction
                      .transaction_data.qr_code
                  }
                  ticket_url={
                    selectedVenda.pagamento.point_of_interaction
                      .transaction_data.ticket_url
                  }
                  qr_code_base64={
                    selectedVenda.pagamento.point_of_interaction
                      .transaction_data.qr_code_base64
                  }
                  description={selectedVenda.pagamento.description}
                  buyerName={selectedVenda.idUserComprador.name}
                  apartment={selectedVenda.idUserComprador.apartamento}
                  tower={selectedVenda.idUserComprador.torre}
                />
              )}

            <Select
              fullWidth
              value={statusPagamento}
              onChange={(e) => setStatusPagamento(e.target.value)}
              label="Status do Pagamento"
              margin="normal"
            >
              <MenuItem value="Pendente">Pendente</MenuItem>
              <MenuItem value="Pago">Pago</MenuItem>
            </Select>
            <Select
              fullWidth
              value={formaPagamento}
              onChange={(e) => setFormaPagamento(e.target.value)}
              label="Forma de Pagamento"
              margin="normal"
            >
              <MenuItem value="Pix">Pix</MenuItem>
              <MenuItem value="Cartão">Cartão</MenuItem>
              <MenuItem value="Dinheiro">Dinheiro</MenuItem>
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal}>Cancelar</Button>
            {loadingAction ? (
              <CircularProgress />
            ) : (
              <Button variant="contained" onClick={handleSave}>
                Salvar
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}

      {/* Modal para troca de mesas */}
      {selectedVenda && (
        <Dialog open={openTroca} onClose={handleCloseTroca}>
          <DialogTitle>Trocar Mesas</DialogTitle>
          <DialogContent>
            <p>
              <strong>Mesas Selecionadas:</strong>{" "}
              {mesasSelecionadas.join(", ")}
            </p>

            <div>
              {mesas.map((mesa) => (
                <Button
                  key={mesa.numero}
                  onClick={() => handleSelectMesa(mesa.numero)}
                  style={{
                    backgroundColor: mesasSelecionadas.includes(mesa.numero)
                      ? "yellow"
                      : mesa.flagDisponivel
                      ? "green"
                      : "gray",
                    margin: "5px",
                    color: "white",
                  }}
                  disabled={
                    !mesa.flagDisponivel &&
                    !mesasSelecionadas.includes(mesa.numero)
                  }
                >
                  {mesa.numero}
                </Button>
              ))}
            </div>
          </DialogContent>
          <DialogActions>
            <IconButton
              onClick={() => handleLiberarMesa(selectedVenda._id)}
              aria-label="Liberar Mesas"
            >
              {" "}
              Liberar Mesa(s)
              {loadingAction ? <CircularProgress /> : <TableBarRoundedIcon />}
            </IconButton>
            <Button onClick={handleCloseTroca}>Cancelar</Button>
            {loadingAction ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                onClick={handleSaveTroca}
                disabled={
                  mesasSelecionadas.length !==
                  selectedVenda.mesasRelacionadas.length
                }
              >
                Salvar
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}

      {/* Mensagens de Sucesso e Erro */}
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage("")}
      >
        <Alert onClose={() => setSuccessMessage("")} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage("")}
      >
        <Alert onClose={() => setErrorMessage("")} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

// Componente para exibir o QR Code e as informações de pagamento
const QRCodeInfo = ({
  qr_code,
  ticket_url,
  qr_code_base64,
  description,
  buyerName,
  apartment,
  tower,
}) => {
  return (
    <div>
      <p>
        <strong>QR Code:</strong>
      </p>
      <QrCodeImage
        src={`data:image/png;base64,${qr_code_base64}`}
        alt="QR Code"
      />
      <p>
        <a href={ticket_url} target="_blank" rel="noopener noreferrer">
          Clique aqui para visualizar o ticket
        </a>
      </p>
    </div>
  );
};

export default ListaVenda;
