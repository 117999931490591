import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { CircularProgress, Snackbar, Alert, Select, MenuItem } from "@mui/material";
import api from "../api/api";

const PageContainer = styled.div`
  padding: 20px;
`;

const TitlePage = styled.h1`
  font-size: 36px;
  font-weight: bold;
  color: #800020;
  text-align: center;
  margin-bottom: 40px;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  background: linear-gradient(90deg, rgba(128, 0, 32, 1) 0%, rgba(255, 255, 255, 1) 100%);
  -webkit-background-clip: text;
  color: transparent;
`;

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 40px;
`;

const Card = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  font-size: 18px;
  color: #333;
`;

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [eventos, setEventos] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState("");

  useEffect(() => {
    fetchEventos();
  }, []);

  useEffect(() => {
    if (selectedEvent) {
      fetchDashboardData(selectedEvent);
    }
  }, [selectedEvent]);

  const fetchEventos = async () => {
    try {
      setLoading(true);
      const response = await api.get("/api/events/");
      setEventos(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrorMessage("Erro ao carregar eventos");
    }
  };

  const fetchDashboardData = async (eventId) => {
    try {
      setLoading(true);
      const response = await api.post("/api/vendas/ingressos", { eventId });
      setDashboardData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrorMessage("Erro ao carregar dados do dashboard");
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <PageContainer>
      <TitlePage>Dashboard de Vendas</TitlePage>

      <Select
        value={selectedEvent}
        onChange={(e) => setSelectedEvent(e.target.value)}
        displayEmpty
        fullWidth
      >
        <MenuItem value="" disabled>
          Selecione um evento
        </MenuItem>
        {eventos.map((evento) => (
          <MenuItem key={evento._id} value={evento._id}>
            {evento.nome}
          </MenuItem>
        ))}
      </Select>

      {dashboardData && (
        <CardGrid>
          <Card>
            <h3>Total de Ingressos</h3>
            <p>{dashboardData.totalIngressos}</p>
          </Card>
          <Card>
            <h3>Valor Total dos Ingressos</h3>
            <p>R$ {dashboardData.valorTotalIngressos.toFixed(2)}</p>
          </Card>
          <Card>
            <h3>Valor Total Pago</h3>
            <p>R$ {dashboardData.valorTotalPago.toFixed(2)}</p>
          </Card>
          <Card>
            <h3>Valor Total Pendente</h3>
            <p>R$ {dashboardData.valorTotalPendente.toFixed(2)}</p>
          </Card>
          <Card>
            <h3>Valor Pix Pendente</h3>
            <p>R$ {dashboardData.valorPixPendente.toFixed(2)}</p>
          </Card>
          <Card>
            <h3>Valor Cartão Pendente</h3>
            <p>R$ {dashboardData.valorCartaoPendente.toFixed(2)}</p>
          </Card>
          <Card>
            <h3>Ingressos Pagos</h3>
            <p>{dashboardData.qtdIngressosPago}</p>
          </Card>
          <Card>
            <h3>Ingressos Pendentes</h3>
            <p>{dashboardData.qtdIngressosPendente}</p>
          </Card>
          <Card>
            <h3>Tamanho de Camisas (Pagas)</h3>
            {Object.entries(dashboardData.tamanhoCamisas).map(([size, count]) => (
              <p key={size}>
                {size}: {count}
              </p>
            ))}
          </Card>
        </CardGrid>
      )}

      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage("")}
      >
        <Alert onClose={() => setErrorMessage("")} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default Dashboard;
